import React from 'react'
import {
  Box,
  Button,
  Text,
  DateRangeButtonFilter,
  CheckboxFilter,
} from 'components'
import { ContentDrawerPortal } from 'compositions/ContentDrawer'
import { FilterProps } from '@kitchen/hooks/useFilteredVendorMenus'
import { DateTime } from 'luxon'

export type FiltersDrawerProps = Pick<FilterProps, "filters" | "filterDefinitions" | "setFilters" | "setAndApplyFilters" | "applyFilters" | "clearFilters" | "applicable" | "clearable">

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({ filters, filterDefinitions, /*applicable,*/ clearable, setFilters, applyFilters, setAndApplyFilters, clearFilters }) => {
  return <ContentDrawerPortal overflow="hidden">
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" overflow="auto" p={2}>
        <Box pb={4}>
          <Text variant="h5" fontWeight="medium">Filters</Text>
        </Box>

        <Box width="100%" pb={4}>
          <Text variant="subtitle1" fontWeight="medium" pb={2}>Date range</Text>

          <Box pt={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'space-around' }}>
            <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newStartDate={DateTime.now().setZone(filters.timeZone).startOf('week', { useLocaleWeeks: true })}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newEndDate={DateTime.now().setZone(filters.timeZone).endOf('week', { useLocaleWeeks: true })}
                setFilters={setAndApplyFilters}
              >This Week</DateRangeButtonFilter>
            </Box>

             <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newStartDate={DateTime.now().setZone(filters.timeZone).startOf('week', { useLocaleWeeks: true }).plus({ weeks: 1 })}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                newEndDate={DateTime.now().setZone(filters.timeZone).endOf('week', { useLocaleWeeks: true }).plus({ weeks: 1 })}
                setFilters={setAndApplyFilters}
              >Next Week</DateRangeButtonFilter>
            </Box>
          </Box>

          <Box pt={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignContent: 'space-around' }}>
            <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                newStartDate={filters.startDate.setZone(filters.timeZone).minus({ weeks: 1 }).startOf('week', { useLocaleWeeks: true })}
                newEndDate={filters.endDate.setZone(filters.timeZone).minus({ weeks: 1 }).endOf('week', { useLocaleWeeks: true })}
                setFilters={setAndApplyFilters}
              >&lt; { filters.startDate.setZone(filters.timeZone).minus({ weeks: 1 }).startOf('week', { useLocaleWeeks: true }).toFormat('LLL d') }</DateRangeButtonFilter>
            </Box>

             <Box>
              <DateRangeButtonFilter
                startDate={filters.startDate}
                endDate={filters.endDate}
                newStartDate={filters.startDate.setZone(filters.timeZone).plus({ weeks: 1 }).startOf('week', { useLocaleWeeks: true })}
                newEndDate={filters.endDate.setZone(filters.timeZone).plus({ weeks: 1 }).endOf('week', { useLocaleWeeks: true })}
                setFilters={setAndApplyFilters}
              >{ filters.endDate.setZone(filters.timeZone).plus({ weeks: 1 }).startOf('week', { useLocaleWeeks: true }).toFormat('LLL d') } &gt;</DateRangeButtonFilter>
            </Box>
          </Box>
        </Box>

        <CheckboxFilter filterName="schoolLocationIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} />
        <CheckboxFilter filterName="productTemperatureTagIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="productDietaryTagIds" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="productType" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} row />
        <CheckboxFilter filterName="hideDates" filterDefinitions={filterDefinitions} filters={filters} setFilters={setFilters} />
      </Box>

      <Box p={2}>
        <Button onClick={() => clearFilters()} color="inherit" variant="text" disabled={!clearable}>Clear</Button>
        <Button variant="contained" onClick={applyFilters}>Apply</Button>
      </Box>
    </Box>
  </ContentDrawerPortal>
}

export default FiltersDrawer
