import React from 'react'
import { Box, Container, Button, Text } from 'components'
import { useLogout, useLoginUrl } from 'hooks'

export const NotAllowed: React.FC<{ token?: string }> = () => {
  const logout = useLogout()
  const campusLoginUrl = useLoginUrl('campus')

  return <Container maxWidth="sm">
    <Box textAlign="center">
      <Text variant="h6">It looks like your account does not have access to the Ordo Kitchen app. Maybe you&apos;re looking for one of the other apps:</Text>
    </Box>
    <Box textAlign="center">
      <Button href={campusLoginUrl} variant="text">Ordo Campus</Button>
    </Box>
    <Box textAlign="center">
      <Text variant="h6">Or if you&apos;d like you can log out and try another account.</Text>
    </Box>
    <Box textAlign="center">
      <Button onClick={logout} variant="outlined" color="inherit">Logout</Button>
    </Box>
  </Container>
}

export default NotAllowed
