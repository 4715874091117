import React from 'react'
import { useLogin } from 'hooks'
import { Text, Container } from 'components'
import { LoginForm } from 'compositions/LoginForm'

export const Login: React.FC = () => {
  const { submitLogin } = useLogin()

  return <Container maxWidth="sm">
    <Text variant="h4">Login</Text>
    <LoginForm
      onSubmit={submitLogin}
    />
  </Container>
}

export default Login
