import React from 'react'
import { Product } from '@kitchen/graphql/schema/graphql'
import { Box } from 'components'
import { ProductCard } from '@kitchen/components/ProductCard'

export const ProductsGrid: React.FC<{ products: Product[] }> = ({ products }) => {
  const sortedProducts = products.map((p) => p).sort((a, b) => a.name.localeCompare(b.name))

  return <Box display="flex" flexWrap="wrap" gap={4} pb={6}>
    { sortedProducts.map((product) => <ProductCard key={product.id} product={product} />)}
  </Box>
}

export default ProductsGrid
