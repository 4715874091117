import React from 'react'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { Admin as AdminIcon, Help as HelpIcon, School as SchoolIcon, Menu as MenuIcon } from 'icons'
import { useMe, useHelpDialog, useLoginUrl } from 'hooks'
import { MenuLink, Text } from 'components'
import { NavDrawer as AppNavDrawer, NavDrawerProps, NavMenuItem } from 'compositions/NavDrawer'
import { HelpDialog } from '@kitchen/compositions/HelpDialog'
import { LocationSelector } from '@kitchen/components'
import { useCurrentVendorLocation } from '@kitchen/hooks'
import { routes } from '@kitchen/routes'

export const NavigationMenuItems: React.FC<{ me: ReturnType<typeof useMe> }> = () => {
  const { hasRole, hasLevel } = useMe()
  const { vendorLocation } = useCurrentVendorLocation()

  return <>
    { routes.filter((route) => route.parent === undefined).map((route) => {
      if (route.internal && route.role && !hasRole(route.role)) return null
      if (route.internal && route.level && !hasLevel(route.level)) return null
      if (route.role && !hasRole(route.role, vendorLocation)) return null
      if (route.level && !hasLevel(route.level, vendorLocation)) return null
      return <NavMenuItem key={route.path} route={route} routes={routes.filter((r) => r.parent === route.name)} />
    })}
  </>
}

export const AccountMenuItems: React.FC<{ me: ReturnType<typeof useMe>, onClick?: React.MouseEventHandler }> = ({ me, onClick }) => {
  const { me: user } = me
  const vendorLocations = user?.vendorLocations?.filter((loc) => me.hasLocationRole('STAFF', loc))
  const { vendorLocation, setCurrentVendorLocation } = useCurrentVendorLocation()
  const campusLoginUrl = useLoginUrl('campus')
  const kioskLoginUrl = useLoginUrl('kiosk')
  const luxoLoginUrl = useLoginUrl('luxo')

  return <>
    { vendorLocations?.length > 1 && <>
      <MenuItem disableRipple disableTouchRipple sx={{ ['&:hover, &:focus, &:active, &:target, &:focus-within, &:visited, &:focus-visible']: { backgroundColor: 'inherit' } }} onKeyDown={(e) => e.stopPropagation()}>
        <LocationSelector vendorLocations={vendorLocations} currentLocation={vendorLocation} setCurrentVendorLocation={setCurrentVendorLocation} />
      </MenuItem>

      <Divider />
    </> }

    { user.schoolLocations?.length > 0 && me.hasAnyRole('STAFF', 'SCHOOL_LOCATION') && <MenuItem onClick={onClick}>
      <MenuLink href={campusLoginUrl}>
        <Text sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.campus.main })}>
          <SchoolIcon sx={{ marginRight: 1 }} />
          Ordo Schools
        </Text>
      </MenuLink>
    </MenuItem> }

    {/* TODO: Remove the admin check when we're ready to launch */}
    { me.hasAdminLevel(1) && user.schoolLocations?.length > 0 && me.hasAnyRole('KIOSK', 'SCHOOL_LOCATION') && <MenuItem onClick={onClick}>
      <MenuLink href={kioskLoginUrl}>
        <Text sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.kiosk.main })}>
          <MenuIcon sx={{ marginRight: 1 }} />
          Ordo Kiosk
        </Text>
      </MenuLink>
    </MenuItem> }

    { me.hasAdminLevel(1) && <MenuItem onClick={onClick}>
      <MenuLink href={luxoLoginUrl}>
        <Text sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.luxo.main })}>
          <AdminIcon sx={{ marginRight: 1 }} />
          Ordo Admin
        </Text>
      </MenuLink>
    </MenuItem> }
  </>
}

export const NavDrawer: React.FC<Omit<NavDrawerProps, "AccountMenuItems" | "NavigationMenuItems" | "currentLocation">> = (props) => {
  const { vendorLocation } = useCurrentVendorLocation()
  const helpDialog = useHelpDialog()

  return <AppNavDrawer {...props} NavigationMenuItems={NavigationMenuItems} AccountMenuItems={AccountMenuItems} currentLocation={vendorLocation}>
    <HelpDialog {...helpDialog} />

    <MenuList id="help-menu">
      <MenuItem>
        <MenuLink onClick={helpDialog.openHelpDialog} onTouchEnd={helpDialog.openHelpDialog} px={4}>
          <HelpIcon sx={{ marginRight: 1 }} />
          Request Help
        </MenuLink>
      </MenuItem>
    </MenuList>
  </AppNavDrawer>
}

export default NavDrawer
