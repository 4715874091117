import { useMemo } from 'react'
import { vendorMenusQuery } from '@kitchen/graphql/queries'
import { useKitchenQuery } from './useKitchenQuery'
import type { Menu, Scalars } from '@kitchen/graphql/schema/graphql'
import { DateTime } from 'luxon'

type VendorMenusQueryArgs = {
  organizationId: string
  locationId?: string
  startDate?: Scalars['ISO8601DateTime']['input']
  endDate?: Scalars['ISO8601DateTime']['input']
}

type VendorMenusArgs = VendorMenusQueryArgs & {
  pollInterval?: number
}

export const useVendorMenus = ({ organizationId, locationId, startDate, endDate, pollInterval }: VendorMenusArgs) => {
  const { data, ...rest } = useKitchenQuery<{ vendorMenus: Menu[] }, VendorMenusQueryArgs>(
    vendorMenusQuery,
    {
      variables: { organizationId, locationId, startDate, endDate },
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data: {
      vendorMenus: [],
      ...data,
    }
  }
}

export default useVendorMenus
