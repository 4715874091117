import { submitVendorSupportRequestMutation } from '@kitchen/graphql/mutations'
import { useKitchenMutation } from './useKitchenMutation'
import type { ClientError, SupportRequestInput } from '@kitchen/graphql/schema/graphql'

type SubmitVendorSupportRequestMutationArgs = {
  locationId: string
  request: SupportRequestInput
}

export const useSubmitVendorSupportRequest = ({ locationId, request }: SubmitVendorSupportRequestMutationArgs) => {
  const result = useKitchenMutation<{ submitVendorSupportRequest: { requestId: string, errors: ClientError[] } }, SubmitVendorSupportRequestMutationArgs>(
    submitVendorSupportRequestMutation,
    {
      variables: { locationId, request },
      notifyOnNetworkStatusChange: true
    }
  )

  return result
}

export default useSubmitVendorSupportRequest
