import React from 'react'
import { Box, Page, Text } from 'components'
import { KitchenSettingsForm } from './KitchenSettingsForm'
import { useCurrentVendorLocation } from '@kitchen/hooks'

export const KitchenSettings: React.FC = () => {
  const { vendorLocation } = useCurrentVendorLocation()

  return <Page>
    <Box mb={4}>
      <Text variant="h4" mb={2}>Manage your kitchen</Text>
      <KitchenSettingsForm kitchen={vendorLocation} />
    </Box>
  </Page>
}

export default KitchenSettings
