import { useMemo } from 'react'
import { vendorProductsQuery } from '@kitchen/graphql/queries'
import { useKitchenQuery } from './useKitchenQuery'
import type { VendorProductsPayload, QueryVendorProductsArgs } from '@kitchen/graphql/schema/graphql'
import { DateTime } from 'luxon'

type VendorProductsArgs = QueryVendorProductsArgs & {
  pollInterval?: number
}

export const useVendorProducts = ({ pollInterval, ...variables }: VendorProductsArgs) => {
  const { data, ...rest } = useKitchenQuery<{ vendorProducts: VendorProductsPayload }, QueryVendorProductsArgs>(
    vendorProductsQuery,
    {
      variables,
      pollInterval,
      notifyOnNetworkStatusChange: true
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadedAt = useMemo(() => DateTime.now(), [data]) // we only want to regenerate this when the data is fetched/changed

  return {
    ...rest,
    loadedAt,
    data
  }
}

export default useVendorProducts
