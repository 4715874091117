import React from 'react'
// import { Link as RouterLink } from 'react-router-dom'
import { NavBar as AppNavBar, NavBarProps } from 'compositions/NavBar'
// import { Link, Text } from 'components'
import OrdoChefsLogo from '@kitchen/theme/ordo-chefs-logo.svg'
// import { useCurrentVendorLocation } from '@kitchen/hooks'
// import { useViewport } from 'hooks'

export const NavBar: React.FC<NavBarProps> = (props) => {
  // const { isDesktop } = useViewport()
  // const { vendorLocation } = useCurrentVendorLocation()

  return <AppNavBar {...props} logo={<OrdoChefsLogo />}>
    {/* { isDesktop && vendorLocation && <Text variant="subtitle1" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="100%" display="inline-block" lineHeight={3}>
      <Link color="inherit" sx={{ fontWeight: 'medium' }} to="/" component={RouterLink}>{ vendorLocation.name === vendorLocation.organization.name ? vendorLocation.name : `${vendorLocation.organization.name} ${vendorLocation.name}` }</Link>
    </Text> } */}
  </AppNavBar>
}

export default NavBar