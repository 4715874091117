import React from 'react'
import { Router } from 'components/Todos'
import { useCurrentVendorLocation, useKitchenTodos } from '@kitchen/hooks'

export const Todos: React.FC = () => {
  const { vendorLocation } = useCurrentVendorLocation()
  const { data: { kitchenTodos }, refetch } = useKitchenTodos({ location: vendorLocation })

  return <Router todos={kitchenTodos.rows} refetch={refetch} />
}

export default Todos
