import React, { useCallback, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import LinearProgress from '@mui/material/LinearProgress'
import {
  Box,
  Button,
  Chip,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from 'components'
import { useCurrentVendorLocation } from '@kitchen/hooks'
import {
  Table as TableIcon,
  Gallery as GalleryIcon,
  Download as DownloadIcon,
} from 'icons'
import { FilterState, FilterProps, PRODUCT_TYPES } from '@kitchen/hooks/useFilteredVendorFulfillmentReports'

export type FiltersHeaderProps = {
  filters: FilterState
} & Pick<FilterProps, "filters" | "setAndApplyFilters" | "loading" | "loadedAt" | "availableSchoolLocations" | "availableProductTags">

export const FiltersHeader: React.FC<FiltersHeaderProps> = ({ loading, loadedAt, filters, availableSchoolLocations, availableProductTags, setAndApplyFilters }) => {
  const { vendorLocation } = useCurrentVendorLocation()

  const setDisplay = useCallback((_e: React.MouseEvent<HTMLElement, MouseEvent>, display: "grid" | "table") => setAndApplyFilters({ display }), [setAndApplyFilters])
  const [tick, setTick] = useState(DateTime.now())

  useEffect(() => {
    const tickInterval = setInterval(() => setTick(DateTime.now()), 5000)
    return () => clearInterval(tickInterval)
  })

  return <>
    <Box display="flex" alignItems="center">
      <Box>
        <Box>
          <Text variant="h4">Orders</Text>
        </Box>
      </Box>

      <Box m={1} flexGrow={1} sx={{ textAlign: "right" }}>
        <Box sx={{ width: '100%' }} display={{ xs: 'none', md: 'block' }}>
          { loading ? <LinearProgress color="primary" /> : <Text key={tick.toISO()} component="span" color="slateGray" fontSize="small">Updated {(loadedAt || DateTime.now()).toRelative()}</Text>}
        </Box>
      </Box>

      <Box>
        <ToggleButtonGroup
          sx={{ marginLeft: 1 }}
          value={filters.display}
          exclusive
          onChange={setDisplay}
        >
          <ToggleButton value="table" size="small">
            <TableIcon />
          </ToggleButton>
          <ToggleButton value="grid" size="small">
            <GalleryIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box ml={1} title={(filters.startDate.toFormat("LLL d") !== filters.endDate.toFormat("LLL d") || DateTime.now() <= DateTime.fromISO(filters.startDate)) ? "Report Unavailable" : "Download PDF Report"}>
        <Button
          href={`/chefs/reports/fulfillment.pdf?vendor_location_id=${vendorLocation?.id}&delivery_date=${filters.startDate.toFormat("y-LL-dd")}`}
          disabled={filters.startDate.toFormat("LLL d") !== filters.endDate.toFormat("LLL d") || DateTime.now() <= DateTime.fromISO(filters.startDate)}
          fullWidth={false}
          sx={{ p: 1, minWidth: 0 }}
          variant="outlined"
          color="inherit"
        >
          <DownloadIcon />
        </Button>
      </Box>
    </Box>

    <Box mb={2}>
      <Box sx={{ width: '100%' }} display={{ xs: 'block', md: 'none' }}>
        { loading ? <LinearProgress color="primary" /> : <Text key={tick.toISO()} component="span" color="slateGray" fontSize="small">Updated {(loadedAt || DateTime.now()).toRelative()}</Text>}
      </Box>
      { filters.startDate.toFormat("LLL d") === filters.endDate.toFormat("LLL d") && <Chip size="small" label={filters.startDate.toFormat("LLL d")} sx={{ mr: 1 }} /> }
      { filters.startDate.toFormat("LLL d") !== filters.endDate.toFormat("LLL d") && <Chip size="small" label={`${filters.startDate.toFormat("LLL dd")} - ${filters.endDate.toFormat("LLL dd")}`} sx={{ mr: 1 }} /> }

      { filters.schoolLocationIds.length === 0 && <Chip size="small" label="All Schools" sx={{ mr: 1 }} /> }
      { filters.schoolLocationIds.map((id) => {
        const location = availableSchoolLocations.find((location) => location.id === id)
        if (!location) return null

        return <Chip size="small" key={location.id} label={location.displayName} sx={{ mr: 1 }} />
      })}

      { <Chip size="small" label={filters.mode.charAt(0).toUpperCase() + filters.mode.slice(1)} sx={{ mr: 1 }} /> }

      { filters.productType.map((id) => {
        const type = PRODUCT_TYPES[id]
        if (!type) return null

        return <Chip size="small" key={id} label={type} sx={{ mr: 1 }} />
      })}

      { filters.productTemperatureTagIds.map((id) => {
        const tag = availableProductTags.find((tag) => tag.id === id)
        if (!tag) return null

        return <Chip size="small" key={tag.id} label={tag.name} sx={{ mr: 1 }} />
      })}

      { filters.productDietaryTagIds.map((id) => {
        const tag = availableProductTags.find((tag) => tag.id === id)
        if (!tag) return null

        return <Chip size="small" key={tag.id} label={tag.name} sx={{ mr: 1 }} />
      })}

      { filters.productName && filters.productName !== '' && <Chip size="small" label={`"${filters.productName}"`} sx={{ mr: 1 }} /> }
    </Box>
  </>
}

export default FiltersHeader
