import React from 'react'
import { Box } from 'components'
import { KitchenSettings } from '@kitchen/compositions'

export const Settings: React.FC = () => {
  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <KitchenSettings />
  </Box>
}

export default Settings
